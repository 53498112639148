@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  color: white;
  font-family: Poppins;
  font-style: normal;
}
body{
  font-family: 'Bungee Inline', cursive;
}

