@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import-normalize;
body,
html {
  font-family: Poppins;


  /* 👇️ or use Operating system default fonts 👇️ */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sanss', 'Helvetica Neue',
    sans-serif; */
}
